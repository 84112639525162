/* WebKit browsers */
.input_placeholder_color input:focus::-webkit-input-placeholder {
  color: #E0E0E0;
}
/* Mozilla Firefox 4 to 18 */
.input_placeholder_color input:focus:-moz-placeholder {
  color: #E0E0E0;
}
/* Mozilla Firefox 19+ */
.input_placeholder_color input:focus::-moz-placeholder {
  color: #E0E0E0;
}
/* Internet Explorer 10+ */
.input_placeholder_color input:focus:-ms-input-placeholder {
  color: #E0E0E0;
}

input::placeholder{
  color: #6F6F6F;
  font-size: 12px;
}

.keySelection{
  background-color: #f1f1f1;
}